<template>
  <div>
    <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="CMS" active>
            <table class="table table-striped table-inverse table-responsive">
              <thead class="thead-inverse">
              <tr>
                <th class="tdauthor">#</th>
                <th>Summary</th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="(feed,i) in feedInfoFe" :key="i">
                  <td class="tdauthor">
                    <img :src="feed.avatar" :alt="`avatar-${i}`" width="50px"/>
                  </td>
                  <td scope="row">
                    <div>{{feed.date}}</div>
                    {{feed.title}}
                    <template >
                      <div v-html="feed.summary"></div>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-tab>
          <b-tab title="API">
            <table class="table table-striped table-inverse table-responsive">
              <thead class="thead-inverse">
              <tr>
                <th class="tdauthor">#</th>
                <th>Summary</th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="(feed,i) in feedInfoBe" :key="i">
                  <td class="tdauthor">
                    <img :src="feed.avatar" :alt="`avatar-${i}`" width="50px"/>
                  </td>
                  <td scope="row">
                    <div>{{feed.date}}</div>
                    {{feed.title}}
                    <template >
                      <div v-html="feed.summary"></div>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
export default {
  data() {
    return {
      feeds: '',
      feedInfoBe: [],
      feedInfoFe: [],
    }
  },
  methods: {
    loadFeed() {
      const vm = this
      axios('/changelog.xml').then(ok => {
        // console.log(ok.data)
        vm.feedInfoFe = this.parseData(ok)
      })
      axios('/changelog-be.xml').then(ok => {
        // console.log(ok.data)
        vm.feedInfoBe = this.parseData(ok)
      })
    },
    parseData(ok) {
      const xmlData = ok.data
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(xmlData, 'text/xml')
      // console.log(xmlDoc)
      const entries = xmlDoc.getElementsByTagName('entry')
      const feedInfo = []
      for (let index = 0; index < entries.length; index++) {
        const item = entries[index]
        const title = item.getElementsByTagName('title')[0].textContent
        const date = moment(item.getElementsByTagName('updated')[0].textContent).fromNow(true)
        const summary = item.getElementsByTagName('summary')[0].innerHTML
        const avatar = item.getElementsByTagName('media:thumbnail')[0].getAttribute('url')
        feedInfo.push({
          avatar: avatar,
          title: title,
          date: date,
          summary: summary,
        })
      }
      return feedInfo
    },
  },
  created() {
    this.loadFeed()
  },
}
</script>
<style >
.tdauthor{
  width: 100px;
}
</style>
